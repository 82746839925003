.box {
  width: 100px;
  height: 100px;
  background: var(--clr-buildyou-primary);
  display: 'flex';
  flex-direction: 'column';
  margin-top: 120px;
  justify-content: 'center';
  align-items: 'center';
}

.button-wrapper {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 11px;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
}

.main-content {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.error-message {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 12px;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  color: var(--clr-error);
}

.form-error-message {
  font-size: 12px;
  line-height: 18px;
  /* margin-bottom: 14px; */
  max-width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  color: var(--clr-error);
}

.hide-focus .MuiDataGrid-cell:focus {
  outline: none;
}