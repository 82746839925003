.dashboard {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.header {
    background-color: #f9fafc;
    width: 240px;
    z-index: 200;
    display: flex;
    flex-direction: column;
}

.header__item {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    height: 5rem;
    border-right: 1px solid #cfdbe6;
    padding: 28px 0;
    width: inherit;
}

.BY_Logo {
    width: 164px;
    height: 44.924px;
    fill: #ffa41b;
}

nav {
    position: relative;
    z-index: 1;
    width: inherit;
    border-right: 1px solid #cfdbe6;
    height: 100%;
    margin-left: auto;
    min-height: 100%;
    overflow-y: auto;
}

.menu-item {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: white;
    border-radius: 12px;
    min-height: 42px;
    padding: 0 12px;
    margin: 0 12px 10px;
    cursor: pointer;
}

.menu-item__icon--margin-left {
    margin-left: 20px; 
  }

.menu-item__text {
    margin: 0 10px;
}


.menu__list {
    all: unset;
}
  
.menu__item {
    margin-bottom: 10px;
}

.menu-item--active {
    color: white;
    background-color: var(--clr-buildyou-primary);
}

  
.menu-item--inactive {
    color: var(--clr-inactive);
}


.dashboard__main {
    width: 100%;
    display: flex;
}

.dashboard__section {
    padding-left: 10px;
    background-color: #f9fafc;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    margin-left: auto;
    min-height: 100%;
    overflow-y: auto;
}

