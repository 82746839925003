.box {
  width: 100px;
  height: 100px;
  background: var(--clr-buildyou-primary);
  display: 'flex';
  flex-direction: 'column';
  margin-top: 120px;
  justify-content: 'center';
  align-items: 'center';
}

.button-wrapper {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 11px;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
}

.left-container {
  display: flex;
  width: 50%;
  background-color: #2b2b52;
  height: 100vh;
  min-height: 35rem;
  flex: 0 0 50%;
  max-width: 50%;
}

.left-container-content {
  padding-left: 0;
  padding-right: 0;
  max-width: 24rem;
  padding: 2rem;
  width: 100%;
  height: 100%;
  margin: auto;
}


.left-container h2 {
  font-size: 24px;
  line-height: 42px;
  font-weight: 700;
  color: white;
  margin-bottom: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.right-container {
  width: 50%;
  height: 100vh;
  min-height: 35rem;
  flex: 0 0 50%;
  max-width: 50%;
  /* overflow-y: auto; */
}

.right-container-content {
  padding: 0;
  max-width: 32rem;
  margin: auto;
  height: 100%;
  width: 100%;
}  

.right-flex-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row;
  align-content: center;
}

.right-content {
  max-width: 350px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  margin: auto;
  text-align: left;
}

.right-content h2 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
  color: rgb(255, 164, 27);
  margin-top: 0;
  margin-bottom: 10px;
  box-sizing: inherit;
}

.right-content p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 0;
}

.right-content form {
  padding: 1.2rem 0;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.right-content form > div {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 11px;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
}

.right-content form > div > label {
  margin-bottom: 14px;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: rgb(39, 39, 39);
}

.forgot-password {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 11px;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
}

.form-field {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 11px;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
}

.form-field label {
  margin-bottom: 14px;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: rgb(39, 39, 39);
}


.register-now {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 11px;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
}

.error-message {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 12px;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  color: var(--clr-error);
}

.form-error-message {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 14px;
  max-width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  color: var(--clr-error);
}


.hide-focus .MuiDataGrid-cell:focus {
  outline: none;
}
