.box {
  width: 100px;
  height: 100px;
  background: var(--clr-buildyou-primary);
  display: 'flex';
  flex-direction: 'column';
  margin-top: 120px;
  justify-content: 'center';
  align-items: 'center';
}

h2 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
  color: var(--clr-buildyou-primary);
  margin-top: 0;
  margin-bottom: 10px;
  box-sizing: inherit;
}

.button-wrapper {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 11px;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
}

.form-field {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 11px;
  max-width: 100%;
  /* padding-left: 15px;
  padding-right: 15px; */
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
}

.form-field label {
  margin-bottom: 14px;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: rgb(39, 39, 39);
}

.error-message {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 12px;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  color: var(--clr-error);
}

.form-error-message {
  font-size: 12px;
  line-height: 18px;
  /* margin-bottom: 14px; */
  max-width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  color: var(--clr-error);
}
