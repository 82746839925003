.BY_Logo {
  animation: 1s appear;
  margin: auto;
}
.BY_Logo .a,.BY_Logo .b {
  fill: #ffa41b !important;
}
.BY_Logo .b {
  fill-rule: evenodd;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}