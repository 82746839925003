html, body {
  height: 100%;
  overflow: hidden;
}

:root {
  --clr-black: #020203;
  --clr-white: #ffffff;
  --clr-main-1: #db003d;
  --clr-main-2: #99022c;
  --clr-secondary-1: #707070;
  --clr-secondary-2: #aaaaaa;
  --clr-secondary-3: #d8d8d8;
  --clr-secondary-4: #f6f6f6;
  --clr-error: #eb0000;
  --clr-warn: #ffb100;
  --clr-success: #1bb757;
  --clr-side-a: #cceefc;
  --clr-side-b: #fffccd;

  --clr-warn-darker: #ff7e1b;
  --clr-blue: #1976d2;
  --clr-transparent-background: rgba(0, 0, 0, 0.1);
  --clr-buildyou-primary: #ffa41b;
  --clr-inactive: #7c91a2;
  --clr-inactive-background: #cfdbe6;
  --clr-inactive-background-mui: #eeeeee;
  --clr-active-green: #6de88e;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", sans-serif;
}

.link:hover {
  color: blue;
}

#root, .App, #dashboard {
  height: 100%;
}
